.container {
    display: flex;
    justify-content: center;
}

.content {
    width: 1200px;
    padding: 30px;
}

.societyImage{
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}