.container {
    background-color: #B0754D;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    padding: 30px;
    width: 1200px;
}