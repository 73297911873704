.container {
    background-color: #543B26;
    display: flex;
    justify-content: center;
}

.content {
    width: 1200px;
    padding: 30px;
}

.content form {
    margin-block-end: 1em;
}