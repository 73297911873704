.content {
    width: 1200px;
    padding: 30px;
}

.realContent {
    padding-top: 10px;
    border-radius: 5px;
    background-color: white;
}

.societyImage{
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}