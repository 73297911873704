.container {
    display: flex;
    justify-content: center;
}

.content {
    width: 1200px;
    padding: 30px;
}

.fakeMapToDelete {
    width: 100%;
}