.logoWrapper {
    cursor: pointer;
}

.societyImage {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.cartCounter {
    padding: 2px;
    margin-top: -15px;
    margin-left: 25px;
    position: absolute;
    background-color: #676767;
    color: white;
    border-radius: 2px;
    font-size: 18px;
}