@font-face {
  font-family: "Insignia";   /*Can be any text*/
  src: local("Insignia"),
  url("./fonts/insignia.ttf") format("truetype");
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body, #root {
  min-height: 100vh;
}

body {
  font-family: "insignia", serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

p, h1, h2, h3, h4, h5, h6, button {
  color: white;
  font-family: "insignia", serif;
}

p, button {
  font-size: 25px;
}

h2 {
  font-size: 40px;
  font-weight: normal;
}

button {
  padding: 5px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}